<template>
  <div class="standard-wrapper">
    <div class="banner-wrapper">
      <div class="title">合作标准</div>
      <div class="types-container">
        <div class="title">可入驻商家类型</div>
        <div class="cards">
          <div 
            v-for="(item, index) in typesData"
            :key="index"
            class="card-item"
          >
            <img :data-src="item.icon" alt="" class="icon">
            <div class="title">{{item.title}}</div>
            <div class="hover-mask">
              <div class="desc">{{item.desc}}</div> 
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="qualifications-wrapper">
      <h3 class="title">入驻资质要求</h3>
      <div class="content-box">
        <div class="labels">
          <div 
            v-for="(item, index) in qualificationsData"
            :key="index"
            class="label"
            :class="currentQIndex === index ? 'active' : ''"
            @mouseover="onLabelChange(index)"
          >
            {{ item.type }}
          </div>
        </div>
        <div class="cards">
          <div
            v-for="(m, k) in currentMaterials"
            :key="k" 
            class="card-item"
            :class="k"
          >
            <div class="title">{{materialsType[k]}}</div>
            <pre class="content">{{m}}</pre>
          </div>
        </div>
      </div>
      <div class="btn" @click="jumpCollect">立即入驻</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typesData: [
        {
          title: '品牌方',
          icon: 'https://mktv-in-cdn.mockuai.com/16257968078785895.png',
          desc: '品牌企业本身，或品牌方在电商端的独家代理，或品牌方在直播端的独家代理'
        },
        {
          title: '工厂',
          icon: 'https://mktv-in-cdn.mockuai.com/16257967485916493.png',
          desc: '具有产品生产能力，曾有过其他知名品牌的代工经验'
        },
        {
          title: '供应商',
          icon: 'https://mktv-in-cdn.mockuai.com/16257967486432918.png',
          desc: '拥有属于自己的品牌或拥有自己的店铺品牌，且拥有众多商品的分销权，期望通过直播电商行业销售产品'
        }
      ],
      currentQIndex: 0,
      qualificationsData: [
        {
          type: '食品生鲜',
          materials: {
            subject: `1. 营业执照\n2. 法人身份证正反面（复印件需盖上公司红章）`,
            brand: `1. 品牌商标注册证/受理通知单(TM标)\n2. 品牌授权书：非自有品牌需上传完整授权链路`,
            industry: '《食品生产许可证》或《食品经营许可证》'
          }
        },
        {
          type: '美妆个护 ｜ 数码家电 ｜ 居家百货 ｜ 服饰箱包',
          materials: {
            subject: `1. 营业执照\n2. 法人身份证正反面（复印件需盖上公司红章）`,
            brand: `1. 品牌商标注册证/受理通知单(TM标)\n2. 品牌授权书：非自有品牌需上传完整授权链路`
          }
        },
        {
          type: '出版物（仅限图书）',
          materials: {
            subject: `1. 营业执照\n2. 法人身份证正反面（复印件需盖上公司红章）`,
            industry: `1.《出版物经营许可证》\n2. 提供至少两家出版社直接授权书`
          }
        }
      ],
      materialsType: {
        subject: '主体资质',
        brand: '品牌资质',
        industry: '行业资质'
      }
    }
  },
  computed: {
    currentMaterials() {
      return this.qualificationsData[this.currentQIndex].materials
    }
  },
  methods: {
    onLabelChange(index) {
      this.currentQIndex = index
    },
    jumpCollect() {
      window.open('/collect-store')
    }
  }
}
</script>


<style lang="less" scoped>
  @import '../../style/common.less';

  .standard-wrapper {
    .banner-wrapper {
      position: relative;
      box-sizing: border-box;
      .pxToVw(height, 520);
      .pxToVw(padding-top, 89);
      background: url(https://mktv-in-cdn.mockuai.com/16257967486388142.png) 0 0/cover no-repeat, linear-gradient(133deg, #00092A 0%, #001554 100%);
      >.title {
        text-align: center;
        .pxToVw(line-height, 50);
        .pxToVw(font-size, 36);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
      }
      .types-container {
        box-sizing: border-box;
        position: absolute;
        .pxToVw(left, 240);
        .pxToVw(bottom, -120);
        .pxToVw(width, 1440);
        .pxToVw(height, 456);
        .pxToVw(padding-top, 53);
        .pxToVw(padding-bottom, 61);
        .pxToVw(border-radius, 16);
        background-color: #f9fcff;
        box-shadow: 0px 11px 29px 0px rgba(180, 180, 180, 0.1);
        >.title {
          text-align: center;
          .pxToVw(line-height, 50);
          .pxToVw(font-size, 36);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333;
        }
        .cards {
          display: flex;
          align-items: center;
          .pxToVw(margin-top, 32);
          .pxToVw(margin-left, 40);
          .card-item {
            position: relative;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .pxToVw(width, 440);
            .pxToVw(height, 260);
            // .pxToVw(padding-top, 36);
            .pxToVw(margin-right, 20);
            .pxToVw(border-radius, 16);
            background-color: rgba(255, 255, 255, 0.44);
            box-shadow: 0px -4px 39px 0px rgba(197, 202, 220, 0.21);
            &:last-child {
              margin-right: 0;
            }
            .icon {
              .pxToVw(width, 80);
              .pxToVw(height, 80);
              .pxToVw(margin-bottom, 13);
            }
            .title {
              .pxToVw(margin-bottom, 8);
              .pxToVw(line-height, 50);
              .pxToVw(font-size, 36);
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
            }
            .desc {
              .pxToVw(width, 352);
              .pxToVw(line-height, 20);
              .pxToVw(font-size, 14);
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #666666;
              text-align: center;
            }
            .hover-mask {
              visibility: hidden;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: linear-gradient(90deg, #FF6600 0%, #FC4440 100%);
              .pxToVw(border-radius, 16);
              display: flex;
              align-items: center;
              justify-content: center;
              .desc {
                .pxToVw(width, 350);
                .pxToVw(line-height, 30);
                .pxToVw(font-size, 18);
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 300;
                color: #FFFFFF;
                text-align: left;
              }
            }
            &:hover {
              box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
              .hover-mask {
                visibility: visible;
              }
            }
          }
        }
      }
    }
    .qualifications-wrapper {
      box-sizing: border-box;
      .pxToVw(height, 857);
      .pxToVw(padding-top, 193);
      .pxToVw(padding-bottom, 100);
      >.title {
        .pxToVw(margin-bottom, 52);
        text-align: center;
        .pxToVw(line-height, 50);
        .pxToVw(font-size, 36);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
      .content-box {
        .pxToVw(width, 1440);
        margin: 0 auto;
        .labels {
          display: flex;
          align-items: center;
          justify-content: center;
          .pxToVw(margin-bottom, 30);
          .label {
            box-sizing: border-box;
            .pxToVw(height, 48);
            .pxToVw(line-height, 48);
            .pxToVw(margin-right, 40);
            text-align: center;
            padding: 0 30 * @unitVw;
            border: 1px solid #999;
            .pxToVw(border-radius, 30);
            .pxToVw(font-size, 18);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
            &.active {
              border: none;
              background: linear-gradient(90deg, #FF6600 0%, #FC4440 100%);
              color: #fff;
            }
            &:nth-child(1), &:nth-child(3) {
              .pxToVw(width, 210);
              padding: 0;
            }
          }
        }
        .cards {
          display: flex;
          align-items: center;
          .card-item {
            box-sizing: border-box;
            position: relative;
            flex: 1;
            .pxToVw(height, 240);
            .pxToVw(padding-top, 54);
            .pxToVw(padding-left, 40);
            // .pxToVw(border-radius, 16);
            &:first-child {
              .pxToVw(border-top-left-radius, 16);
              .pxToVw(border-bottom-left-radius, 16);
            }
            &:last-child {
              .pxToVw(border-top-right-radius, 16);
              .pxToVw(border-bottom-right-radius, 16);
            }
            &:nth-child(odd) {
              background: linear-gradient(180deg, #FCFCFC 0%, #F8F8F8 100%);
            }
            &:nth-child(even) {
              background-color: #f8f8f8;
            }
            &::after {
              content: '';
              position: absolute;
              .pxToVw(bottom, -21);
              right: 0;
              .pxToVw(width, 128);
              .pxToVw(height, 128);
              background-size: cover;
              background-repeat: no-repeat;
            }
            &.subject {
              &::after {
                content: '';
                background-image: url(https://mktv-in-cdn.mockuai.com/16258011389586711.png);
              }
            }
            &.brand {
              &::after {
                content: '';
                background-image: url(https://mktv-in-cdn.mockuai.com/16258011389419678.png);
              }
            }
            &.industry {
              &::after {
                content: '';
                background-image: url(https://mktv-in-cdn.mockuai.com/16258011389515163.png);
              }
            }
            .title {
              .pxToVw(line-height, 33);
              .pxToVw(font-size, 24);
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
            }
            .content {
              .pxToVw(margin-top, 40);
              .pxToVw(line-height, 20);
              .pxToVw(font-size, 14);
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #666666;
            }
          }
        }
      }
      .btn {
        .pxToVw(width, 240);
        .pxToVw(height, 60);
        .pxToVw(line-height, 60);
        margin: 100*@unitVw auto;
        text-align: center;
        .pxToVw(border-radius, 30);
        background-color: #ff6600;
        .pxToVw(font-size, 24);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        text-shadow: 0px 7px 21px rgba(255, 102, 0, 0.4);
        cursor: pointer;
        &:hover {
          font-weight: 600;
          box-shadow: 0px 7px 21px 0px rgba(255, 102, 0, 0.4);
        }
      }
    }
  }
</style>