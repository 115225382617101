<template>
  <div class="guide-wrapper">
    <div class="banner-wrapper">
      <h3 class="title">入驻指南</h3>
      <div class="process-container">
        <div class="title">入驻流程</div>
        <div class="process-steps">
          <div 
            v-for="(item, index) in processData"
            :key="index"
            class="process-item"
          >
            <div class="info">
              <div class="icon-box">
                <img :data-src="item.icon" alt="" class="icon">
              </div>
              <div class="title">{{item.title}}</div>
            </div>
            <div class="points">
              <div 
                v-for="(p, i) in item.points"
                :key="i"
                class="point-item"
              >
                <img data-src="https://mktv-in-cdn.mockuai.com/16258089800189447.png" alt="" class="icon">
                <span class="text">{{p}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="btn" @click="jumpCollect">立即入驻</div>
      </div>
    </div>
    <div class="normal-problems">
      <h3 class="title">常见问题</h3>
      <div class="swiper">
        <div class="swiper-arrow prev"></div>
        <div class="swiper-arrow next"></div>
        <swiper :options="swiperOption">
          <swiper-slide
            v-for="(item, index) in questions"
            :key="index"
          >
            <div class="card-box">
              <div class="title">{{item.title}}</div>
              <div class="content">
                <p
                  v-for="(p, i) in item.details"
                  :key="i"  
                  class="text"
                >{{ p }}</p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <p-footer type="flow"></p-footer>
  </div>
</template>

<script>
import Footer from '@/layout/Footer.vue'
export default {
  components: {
    'p-footer': Footer
  },
  data() {
    return {
      processData: [
        {
          title: '01 商品质检',
          icon: 'https://mktv-in-cdn.mockuai.com/16258155849079079.png',
          points: [
            '提交相关资料',
            '平台质检',
            '提供账号信息',
            '获取注册链接'
          ]
        },
        {
          title: '02 资质提交',
          icon: 'https://mktv-in-cdn.mockuai.com/16258089800881169.png',
          points: [
            '上传公司营业执照',
            '填写法人和联系人信息',
            '提交行业资质',
            '上传品牌资质'
          ]
        },
        {
          title: '03 审核通过',
          icon: 'https://mktv-in-cdn.mockuai.com/16258155848961278.png',
          points: [
            '工作人员审核',
            '周期约3-5个工作日'
          ]
        },
        {
          title: '04 入驻完成',
          icon: 'https://mktv-in-cdn.mockuai.com/16258089800472771.png',
          points: [
            '签署《供应商入驻协议》',
            '缴纳店铺保证金',
            '上传商品，等待审核'
          ]
        }
      ],
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: '2.0833333333333335%',
        navigation: {
          nextEl: '.swiper-arrow.next',
          prevEl: '.swiper-arrow.prev',
        },
      },
      questions: [
        {
          title: '供应商入驻合作模式是怎样的？',
          details: [
            `1、魔筷星选是网红和供应商对接的直播电商服务平台，入驻需要先由招商人员评估商家产品情况，满足平台要求后再协助开通供应商店铺（发布商品和日常订单处理后台）；`,
            `2、商家入驻后将由商家运营团队1对1指导服务，协助调整卖点、价格等产品优势，参加平台活动并帮助对接主播直播带货；`,
            `3、主播参与带货后的订单信息会实时同步到供应商后台，商家需要及时安排发货，消费者正常咨询一般由魔筷客服答疑，其他售后问题则有一对一售后对接群沟通处理。`
          ]
        },
        {
          title: '入驻流程怎么安排的？',
          details: [
            '1、提交申请后等待评估及招商沟通；',
            '2、符合平台入驻要求后安排开通供应商后台；',
            '3、提交资质信息审核缴纳保证金；',
            '4、对接商家运营，一对一指导。'
          ]
        },
        {
          title: '入驻商家有哪些服务支持？',
          details: [
            `1、魔筷星选APP开通店铺：魔筷星选APP是专业的直播供应链平台，入驻商家可在魔筷官方平台开通旺铺，发布自己的产品；`,
            `2、魔筷星选APP核心资源位展示：增加产品曝光，让更多主播直接看到你；` ,
            `3、直播运营小二专属服务：包含直播运营培训、指导选品、活动报名、产品优化、店铺诊断等1对1运营服务，手把手指导打造爆款；`,
            `4、全天候售后服务支持：消费者购买产品后的正常咨询由魔筷客服参与回复。`
          ]
        },
        {
          title: '产品佣金如何设置？平台如何结算？',
          details: [
            `1、供应商后台发布商品，需要设置供货价及建议售价2个价格。供货价指平台结算价格，建议售价指粉丝直播间购买价格，佣金=（售价-供货价格）/售价；`,
            `2、平台和商家按照供货价结算，账期是T+7模式（即最快为客户确认收到货品后的第7天开始结算，一般默认是7天收货）。`
          ]
        },
        {
          title: '入驻一般都有哪些资质要求？',
          details: [
            `1.入驻主体为公司或者有限责任公司（个体户不支持）；`,
            `2.营业执照注册满一个月；`,
            `3.商标注册证（商标注册证上并非入驻公司，得有销售授权书）；`,
            `4.银行公户进行交易往来（以入驻公司名义开的银行卡，个人名\n义不属于）；`,
            `5.产品检验报告（部分百货服饰产品不需要）。`
          ]
        }
      ]
    }
  },
  methods: {
    jumpCollect() {
      window.open('/collect-store')
    }
  }
}
</script>


<style lang="less" scoped>
  @import '../../style/common.less';

  .guide-wrapper {
    .banner-wrapper {
      position: relative;
      box-sizing: border-box;
      .pxToVw(height, 520);
      .pxToVw(padding-top, 89);
      background: url(https://mktv-in-cdn.mockuai.com/16258090922573186.png) 0 0/cover no-repeat, linear-gradient(133deg, #00092A 0%, #001554 100%);
      >.title {
        text-align: center;
        .pxToVw(line-height, 50);
        .pxToVw(font-size, 36);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
      }
      .process-container {
        box-sizing: border-box;
        position: absolute;
        .pxToVw(bottom, -299);
        .pxToVw(left, 240);
        .pxToVw(width, 1440);
        .pxToVw(height, 618);
        .pxToVw(padding-top, 53);
        .pxToVw(border-radius, 16);
        border: 1px solid #fff;
        background-color: #F9FCFF;
        background-image: url(https://mktv-in-cdn.mockuai.com/16258147774839652.png);
        background-size: cover;
        background-repeat: no-repeat;
        box-shadow: 0px 11px 29px 0px rgba(180, 180, 180, 0.1);
        >.title {
          text-align: center;
          .pxToVw(line-height, 50);
          .pxToVw(font-size, 36);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
        .process-steps {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .pxToVw(margin-top, 28);
          .pxToVw(margin-left, 120);
          .pxToVw(margin-right, 120);
          .process-item {
            position: relative;
            .pxToVw(padding-bottom, 142);
            &:last-child {
              .icon-box {
                &::after {
                  content: '';
                  width: 0;
                  height: 0;
                }
              }
            }
            .info {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .icon-box {
              position: relative;
              .pxToVw(width, 108);
              .pxToVw(height, 108);
              .pxToVw(margin-bottom, 24);
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgba(255, 255, 255, 0.8);
              box-shadow: 0px -4px 39px 0px rgba(197, 202, 220, 0.21);
              .icon {
                .pxToVw(width, 72);
              }
              &::after {
                content: '';
                position: absolute;
                top: 50%;
                .pxToVw(right, -213);
                transform: translateY(-50%);
                .pxToVw(width, 189);
                .pxToVw(height, 20);
                background-image: url(https://mktv-in-cdn.mockuai.com/16258142733265619.png);
                background-size: cover;
                background-repeat: no-repeat;
              }
            }
            .title {
              .pxToVw(margin-bottom, 13);
              .pxToVw(line-height, 33);
              .pxToVw(font-size, 24);
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
            }
            .points {
              position: absolute;
              // top: 100%;
              // bottom: 0;
              .pxToVw(top, 178);
              .point-item {
                display: flex;
                align-items: center;
                .pxToVw(margin-bottom, 13);
                &:last-child {
                  margin-bottom: 0;
                }
                .icon {
                  .pxToVw(width, 20);
                  .pxToVw(height, 15);
                  .pxToVw(margin-right, 8);
                }
                .text {
                  .pxToVw(line-height, 20);
                  .pxToVw(font-size, 14);
                  font-family: PingFangSC-Light, PingFang SC;
                  font-weight: 300;
                  color: #333333;
                  white-space: pre;
                }
              }
            }
          }
        }
        .btn {
          margin: 60 * @unitVw auto 0;
          .pxToVw(width, 240);
          .pxToVw(height, 60);
          .pxToVw(line-height, 60);
          text-align: center;
          background-color: #ff6600;
          .pxToVw(border-radius, 30);
          .pxToVw(font-size, 24);
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          cursor: pointer;
          &:hover {
            font-weight: 600;
            box-shadow: 0px 7px 21px 0px rgba(255, 102, 0, 0.4);
          }
        }
      }
    }
    .normal-problems {
      box-sizing: border-box;
      .pxToVw(height, 1137);
      .pxToVw(padding-top, 373);
      >.title {
        text-align: center;
        .pxToVw(line-height, 50);
        .pxToVw(font-size, 36);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
      .swiper {
        position: relative;
        margin: 34*@unitVw 240*@unitVw 0;
        .swiper-arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          .pxToVw(width, 43);
          .pxToVw(height, 43);
          background-size: cover;
          background-repeat: no-repeat;
          cursor: pointer;
          &.prev {
            .pxToVw(left, -53);
            background-image: url(https://mktv-in-cdn.mockuai.com/16252291642899934.png);
          }
          &.next {
            .pxToVw(right, -53);
            background-image: url(https://mktv-in-cdn.mockuai.com/16252259933873886.png);
          }
        }
        .card-box {
          position: relative;
          box-sizing: border-box;
          .pxToVw(width, 460);
          .pxToVw(height, 580);
          padding: 56*@unitVw 30*@unitVw 58*@unitVw;
          .pxToVw(border-radius, 16);
          background-color: #f8f8f8;
          background-size: 200*@unitVw 200*@unitVw;
          background-position: bottom -63*@unitVw right 19*@unitVw;
          background-repeat: no-repeat;
          overflow: hidden;
          .title {
            .pxToVw(margin-bottom, 46);
            .pxToVw(line-height, 40);
            .pxToVw(font-size, 24);
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
          }
          .content {
            .text {
              .pxToVw(max-width, 400);
              .pxToVw(margin-bottom, 34);
              .pxToVw(line-height, 24);
              .pxToVw(font-size, 14);
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
          }
          // &::after {
          //   content: '';
          //   position: absolute;
          //   .pxToVw(right, 19);
          //   .pxToVw(bottom, -63);
          //   .pxToVw(width, 200);
          //   .pxToVw(height, 200);
          //   background-size: cover;
          //   background-repeat: no-repeat;
          // }
        }
        .swiper-slide {
          &:nth-child(1) {
            .card-box {
              background-image: url(https://mktv-in-cdn.mockuai.com/16258198744005602.png);  
            }
          }
          &:nth-child(2) {
            .card-box {
              background-image: url(https://mktv-in-cdn.mockuai.com/16258198743967756.png);
            }
          }
          &:nth-child(3) {
            .card-box {
              background-image: url(https://mktv-in-cdn.mockuai.com/16258198743934024.png);
            }
          }
          &:nth-child(4) {
            .card-box {
              background-image: url(https://mktv-in-cdn.mockuai.com/16258198743903479.png);
            }
          }
          &:nth-child(5) {
            .card-box {
              background-image: url(https://mktv-in-cdn.mockuai.com/16258198743809032.png);
            }
          }
        }
      }
    }
  }
</style>
