<template>
  <div class="solution-wrapper">
    <div class="banner-wrapper">
      <h1 class="title">触达海量网红，成就爆款商品</h1>
      <div class="desc">布局直播电商，助力商家成长</div>
      <div class="actions">
        <div class="btn btn-white" @click="jumpCollect">商家入驻</div>
        <div class="btn" @click="showVideo">观看视频</div>
      </div>
    </div>
    <div class="core-section">
      <h2 class="title">用魔筷，获取电商增长新动能</h2>
      <div class="desc">入驻魔筷，轻松收益，让主播为您带货</div>
      <div class="name">
        <img
          src="https://mktv-in-cdn.mockuai.com/16275456820457442.png"
          alt=""
          class="icon"
        />
        <h3 class="text">核心服务</h3>
      </div>
      <div class="content-box">
        <div class="info">
          <h4 class="title">链接海量主播 快速布局直播电商</h4>
          <div class="slide-tabs">
            <div class="active-bar" :style="coreBarStyle"></div>
            <div class="tabs-box">
              <div
                v-for="(item, index) in coreData"
                :key="index"
                class="tab-item"
                :class="currentCoreIndex === index ? 'active' : ''"
                @mouseover="onCoreTabChange(index)"
              >
                {{ item.tabName }}
              </div>
            </div>
          </div>
          <div class="points">
            <div
              v-for="(p, i) in currentCoreItem.points"
              :key="i"
              class="point-item"
            >
              <img
                src="https://mktv-in-cdn.mockuai.com/16252126301407665.png"
                alt=""
                class="icon"
              />
              <span class="text">{{ p }}</span>
            </div>
          </div>
        </div>
        <div class="mobile-wrapper" ref="mobileWrapper">
          <img
            v-if="currentCoreItem.decorateImg"
            :src="currentCoreItem.decorateImg"
            alt=""
            class="decorate-img"
          />
          <div class="mobile-box">
            <img :src="currentCoreItem.img" alt="" class="mobile-img" />
          </div>
        </div>
      </div>
    </div>
    <div class="tools-section">
      <div class="name">
        <img
          src="https://mktv-in-cdn.mockuai.com/16275456820342800.png"
          alt=""
          class="icon"
        />
        <h3 class="text">多工具支持</h3>
      </div>
      <div class="content-box">
        <div class="info">
          <h4 class="title">多款工具支持 一站解决管理难题</h4>
          <div class="slide-tabs">
            <div class="active-bar" :style="toolsBarStyle"></div>
            <div class="tabs-box">
              <div
                v-for="(item, index) in toolsData"
                :key="index"
                class="tab-item"
                :class="currentToolsIndex === index ? 'active' : ''"
                @mouseover="onToolsTabChange(index)"
              >
                {{ item.tabName }}
              </div>
            </div>
          </div>
          <div class="points">
            <div
              v-for="(p, i) in currentToolsItem.points"
              :key="i"
              class="point-item"
            >
              <img
                src="https://mktv-in-cdn.mockuai.com/16252126301407665.png"
                alt=""
                class="icon"
              />
              <span class="text">{{ p }}</span>
            </div>
          </div>
          <a
            v-if="currentToolsItem.more"
            :href="currentToolsItem.more"
            target="_blank"
            class="more"
            >了解更多功能</a
          >
        </div>
        <div class="mobile-wrapper" ref="mobileWrapper">
          <img
            v-if="currentToolsItem.decorateImg"
            :src="currentToolsItem.decorateImg"
            alt=""
            class="decorate-img"
          />
          <div class="mobile-box">
            <img :src="currentToolsItem.img" alt="" class="mobile-img" />
          </div>
        </div>
      </div>
    </div>
    <div class="aftersale-section">
      <div class="name">
        <img
          src="https://mktv-in-cdn.mockuai.com/16275456820022625.png"
          alt=""
          class="icon"
        />
        <h3 class="text">物流与售后</h3>
      </div>
      <div class="content-box">
        <div class="info">
          <h4 class="title">组合式服务体系 自如应对物流与售后</h4>
          <div class="slide-tabs">
            <div class="active-bar" :style="aftersaleBarStyle"></div>
            <div class="tabs-box">
              <div
                v-for="(item, index) in aftersaleData"
                :key="index"
                class="tab-item"
                :class="currentAftersaleIndex === index ? 'active' : ''"
                @mouseover="onAftersaleTabChange(index)"
              >
                {{ item.tabName }}
              </div>
            </div>
          </div>
          <div class="points">
            <div
              v-for="(p, i) in currentAftersaleItem.points"
              :key="i"
              class="point-item"
            >
              <img
                src="https://mktv-in-cdn.mockuai.com/16252126301407665.png"
                alt=""
                class="icon"
              />
              <span class="text">{{ p }}</span>
            </div>
          </div>
          <a
            v-if="currentAftersaleItem.more"
            :href="currentAftersaleItem.more"
            target="_blank"
            class="more"
            >详询了解更多</a
          >
        </div>
        <div class="computer-wrapper" ref="computerWrapper">
          <img
            v-if="currentAftersaleItem.decorateImg"
            :src="currentAftersaleItem.decorateImg"
            alt=""
            class="decorate-img"
          />
          <div class="computer-box">
            <img :src="currentAftersaleItem.img" alt="" class="computer-img" />
          </div>
        </div>
      </div>
    </div>

    <div class="empowerment-wrapper">
      <div class="content-box">
        <div class="top">
          <h4 class="title">全方位赋能<br />深度合作共同打造平台爆款</h4>
          <div class="desc">
            魔筷的专业商品运营服务团队，将为商家输出公司积累的成熟运营经验，通过从生产到包装的全链路服务，帮助商家或工厂打造爆款产品。
          </div>
        </div>
        <div class="slides-wrapper">
          <div
            v-for="(item, index) in slidesData"
            :key="index"
            class="slide-item"
            :class="[item.bgClass, currentSlideIndex === index ? 'active' : '']"
            @mouseover="onSlideChange(index)"
          >
            <div v-show="currentSlideIndex === index" class="mask"></div>
            <div class="info">
              <div class="title">{{ item.title }}</div>
              <transition name="fade">
                <div v-show="currentSlideIndex === index" class="points">
                  <div
                    v-for="(p, i) in item.points"
                    :key="i"
                    class="point-item"
                  >
                    <img
                      data-src="https://mktv-in-cdn.mockuai.com/16252126301407665.png"
                      alt=""
                      class="icon"
                    />
                    <span class="text">{{ p }}</span>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="brands-wrapper">
      <h2 class="title">还在等什么？数万商家已与魔筷合作</h2>
      <div class="logo-box">
        <img class="brand-img" src="https://mktv-in-cdn.mockuai.com/16623608165824140.png" alt=""> 
      </div>
      <div class="btn" @click="jumpCollect">立即入驻</div>
    </div>
    <video-modal
      :visible="videoModalVisible"
      :src="videoSrc"
      @close="closeVideo"
    ></video-modal>
  </div>
</template>

<script>
import VideoModal from '@/components/VideoModal.vue'
export default {
  components: {
    VideoModal,
  },
  data() {
    return {
      videoModalVisible: false,
      videoSrc: '',
      activeBarLeft: (2 * 100) / 1920,
      coreBarLeft: (2 * 100) / 1920,
      toolsBarLeft: (2 * 100) / 1920,
      aftersaleBarLeft: (2 * 100) / 1920,
      coreData: [
        {
          tabName: '多点触达',
          img: 'https://mktv-in-cdn.mockuai.com/16276329784742134.png',
          points: [
            'APP端多个资源位',
            '短视频、公众号等其他渠道推广',
            '经纪人推荐',
          ],
        },
        {
          tabName: '精细化运营',
          img: 'https://mktv-in-cdn.mockuai.com/16276329784682573.png',
          decorateImg: 'https://mktv-in-cdn.mockuai.com/16256524104472814.png',
          points: [
            '大数据支持',
            '人工智能算法推荐',
            '1对1商家运营团队服务推品',
          ],
        },
        {
          tabName: '活动支持',
          img: 'https://mktv-in-cdn.mockuai.com/16276355362084013.png',
          points: ['日常平台营销活动', '大型活动组织', '专场活动策划'],
        },
      ],
      currentCoreIndex: 0,
      toolsData: [
        {
          tabName: '魔筷星选APP',
          img: 'https://mktv-in-cdn.mockuai.com/16275551120104443.png',
          points: ['直连多平台主播', '操作简单', '分销一步搞定'],
        },
        {
          tabName: '客户管理工具',
          more: '/scrm',
          img: 'https://mktv-in-cdn.mockuai.com/16257256033847729.png',
          points: ['自建商品私域流量', '维护粉丝关系', '稳定提升复购率'],
        },
        {
          tabName: '大数据后台工具',
          img: 'https://mktv-in-cdn.mockuai.com/16276329784484565.png',
          points: ['精准化推荐', '智能数据分析', '对接高价值主播'],
        },
      ],
      currentToolsIndex: 0,
      aftersaleData: [
        {
          tabName: '物流管理',
          bgClass: 'bg-logistics',
          img: 'https://mktv-in-cdn.mockuai.com/16276358584001577.png',
          points: ['开放式ERP', '订单追踪管理', '直选物流服务商'],
        },
        {
          tabName: '魔筷云仓',
          more: '/collect-cloudstore',
          bgClass: 'bg-cloudhouse',
          img: 'https://mktv-in-cdn.mockuai.com/16309231466151968.png',
          points: ['24小时发货率 99%', '专业团队服务', '满意率大于90%'],
        },
        {
          tabName: '客服支持',
          bgClass: 'bg-customer',
          img: 'https://mktv-in-cdn.mockuai.com/16276358583917980.png',
          points: ['千人客服团队', '24小时全天候服务支持', '服务好评率>95%'],
        },
      ],
      currentAftersaleIndex: 0,

      currentSlideIndex: 0,
      slidesData: [
        {
          title: '生产优化',
          bgClass: 'bg-prod',
          points: ['成本优化', '流水线优化', '物流优化'],
        },
        {
          title: '品牌策划',
          bgClass: 'bg-brand',
          points: ['品牌定位', 'VI 标准建立', '包装优化'],
        },
        {
          title: '直播策划',
          bgClass: 'bg-live',
          points: ['溯源活动', '经纪人推品', '流量运营'],
        },
        {
          title: '营销推广',
          bgClass: 'bg-operation',
          points: ['平台种草', '明星代言', '全平台造势'],
        },
      ],
      logoContainerLeft: 0,
    }
  },
  computed: {
    currentCoreItem() {
      return this.coreData[this.currentCoreIndex]
    },
    currentToolsItem() {
      return this.toolsData[this.currentToolsIndex]
    },
    currentAftersaleItem() {
      return this.aftersaleData[this.currentAftersaleIndex]
    },
    coreBarStyle() {
      return {
        left: `${this.coreBarLeft}vw`,
      }
    },
    toolsBarStyle() {
      return {
        left: `${this.toolsBarLeft}vw`,
      }
    },
    aftersaleBarStyle() {
      return {
        left: `${this.aftersaleBarLeft}vw`,
      }
    },
    barStyle() {
      return {
        left: `${this.activeBarLeft}vw`,
      }
    },
    logoContainerStyle() {
      return {
        left: `${this.logoContainerLeft}vw`,
      }
    },
  },
  mounted() {},
  methods: {
    onSlideChange(index) {
      this.currentSlideIndex = index
    },

    onCoreTabChange(index) {
      this.currentCoreIndex = index
      this.coreBarLeft = ((2 + index * 180) * 100) / 1920
    },
    onToolsTabChange(index) {
      this.currentToolsIndex = index
      this.toolsBarLeft = ((2 + index * 200) * 100) / 1920
    },
    onAftersaleTabChange(index) {
      this.currentAftersaleIndex = index
      this.aftersaleBarLeft = ((2 + index * 180) * 100) / 1920
    },
    jumpCollect() {
      // window.location.href = '/collect-store'
      window.open('/collect-store')
    },
    showVideo() {
      this.videoSrc = 'https://mktv-in-cdn.mockuai.com/16273841811488664.mp4'
      this.videoModalVisible = true
    },
    closeVideo() {
      this.videoModalVisible = false
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../style/common.less';

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-creative {
  0% {
    opacity: 0;
    transform: translate3d(0, 90px, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes rolling {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100 * 3200/1536%);
  }
}

.fade-in-creative {
  animation: fade-in-creative 1s ease 0s normal both;
}
.fade-in {
  animation: fade-in 1s ease 0s 1 normal both;
}

.solution-wrapper {
  // height: 2000px;
  .banner-wrapper {
    box-sizing: border-box;
    .pxToVw(height, 680);
    .pxToVw(padding-top, 204);
    .pxToVw(padding-bottom, 215);
    background-color: #00092a;
    background-image: url(https://mktv-in-cdn.mockuai.com/16256273266334624.png);
    background-size: cover;
    background-repeat: no-repeat;
    h1.title {
      .pxToVw(margin-bottom, 11);
      text-align: center;
      line-height: 1.4;
      .pxToVw(font-size, 60);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
    .desc {
      text-align: center;
      .pxToVw(line-height, 33);
      .pxToVw(font-size, 24);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
    .actions {
      .pxToVw(margin-top, 73);
      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        box-sizing: border-box;
        .pxToVw(width, 220);
        .pxToVw(height, 60);
        .pxToVw(line-height, 60);
        text-align: center;
        .pxToVw(margin-right, 40);
        .pxToVw(border-radius, 30);
        border: 1px solid #fff;
        .pxToVw(font-size, 24);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
        &:hover {
          border: none;
          background-color: #fff;
          color: #333;
        }
        &:last-child {
          margin-right: 0;
        }
        &.btn-white {
          background-color: #fff;
          color: #ff6600;
          &:hover {
            background-color: #ff6600;
            color: #fff;
          }
        }
      }
    }
  }
  .functions-wrapper {
    box-sizing: border-box;
    .pxToVw(height, 1100);
    .pxToVw(padding-top, 109);
    .pxToVw(padding-bottom, 150);
    background-color: #fff;
    background-image: url(https://mktv-in-cdn.mockuai.com/16256498222339346.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 716 * @unitVw 700 * @unitVw;
    > .title {
      text-align: center;
      .pxToVw(margin-bottom, 8);
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    > .desc {
      text-align: center;
      .pxToVw(line-height, 25);
      .pxToVw(font-size, 18);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    .content-box {
      box-sizing: border-box;
      display: flex;
      .pxToVw(width, 1440);
      .pxToVw(height, 600);
      margin: 158 * @unitVw auto;
      background-color: #001554;
      background-size: cover;
      background-repeat: no-repeat;
      .pxToVw(border-radius, 16);
      &.bg-core {
        background-image: url(https://mktv-in-cdn.mockuai.com/16256518357993907.png);
        .sub-tabs {
          .pxToVw(width, 540);
          .active-bar {
            .pxToVw(width, 180);
          }
          .sub-tab-item {
            .pxToVw(width, 180);
          }
        }
      }
      &.bg-tools {
        background-image: url(https://mktv-in-cdn.mockuai.com/16256517660396134.png);
        .sub-tabs {
          .pxToVw(width, 600);
          .active-bar {
            .pxToVw(width, 200);
          }
          .sub-tab-item {
            .pxToVw(width, 200);
          }
        }
      }
      &.bg-aftersale {
        background-image: url(https://mktv-in-cdn.mockuai.com/16256517491307362.png);
        .sub-tabs {
          .pxToVw(width, 540);
          .active-bar {
            .pxToVw(width, 180);
          }
          .sub-tab-item {
            .pxToVw(width, 180);
          }
        }
      }
      .tabs {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        border-right: 1px solid rgba(216, 216, 216, 0.2);
        .tab-item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          .pxToVw(width, 260);
          .pxToVw(font-size, 30);
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.8);
          background-color: rgba(36, 36, 85, 0.3);
          &.active {
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            background-color: rgba(255, 102, 0, 0.8);
            color: #fff;
          }
          &:first-child {
            .pxToVw(border-top-left-radius, 16);
          }
          &:last-child {
            .pxToVw(border-bottom-left-radius, 16);
          }
        }
      }
      .panel {
        position: relative;
        flex: 1;
        .sub-tabs {
          // box-sizing: border-box;
          position: relative;
          .pxToVw(padding, 2);
          .pxToVw(height, 54);
          background-color: rgba(255, 255, 255, 0.2);
          .pxToVw(border-radius, 31);
          .active-bar {
            position: absolute;
            .pxToVw(height, 54);
            .pxToVw(border-radius, 31);
            background: linear-gradient(90deg, #ff6600 0%, #fc4440 100%);
            transition: left 0.5s;
          }
          .sub-tabs-box {
            position: absolute;
            display: flex;
            align-items: center;
            // background-color: rgba(255, 255, 255, .2);
            .pxToVw(border-radius, 31);
            .sub-tab-item {
              .pxToVw(line-height, 54);
              text-align: center;
              .pxToVw(border-radius, 31);
              .pxToVw(font-size, 24);
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: rgba(255, 255, 255, 0.8);
              cursor: pointer;
              &.active {
                color: #fff;
              }
            }
          }
        }
        .points {
          .point-item {
            display: flex;
            align-items: center;
            line-height: 2;
            .icon {
              .pxToVw(width, 20);
              .pxToVw(height, 15);
              .pxToVw(margin-right, 10);
            }
            .text {
              .pxToVw(font-size, 18);
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #d6d6d6;
            }
          }
        }
        .more {
          .pxToVw(width, 220);
          .pxToVw(height, 60);
          .pxToVw(line-height, 60);
          text-align: center;
          .pxToVw(border-radius, 30);
          background-color: #fff;
          .pxToVw(font-size, 24);
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ff6600;
          cursor: pointer;
        }
        .panel-mobile {
          position: relative;
          display: flex;
          align-items: center;
          height: 100%;
          .info {
            flex: 1;
            position: relative;
            .pxToVw(margin-left, 80);
            .title {
              .pxToVw(line-height, 54);
              .pxToVw(font-size, 36);
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #ffffff;
            }
            .sub-tabs {
              .pxToVw(margin-top, 66);
              .pxToVw(margin-bottom, 31);
            }

            .more {
              position: absolute;
              .pxToVw(left, 380);
              .pxToVw(bottom, 24);
            }
          }
          .mobile-wrapper {
            position: absolute;
            .pxToVw(right, 15);
            .pxToVw(top, -119);
            .pxToVw(width, 444);
            .pxToVw(height, 834);
            background-image: url(https://mktv-in-cdn.mockuai.com/16256517491543627.png);
            background-size: cover;
            background-repeat: no-repeat;
            .decorate-img {
              position: absolute;
              z-index: 20;
              .pxToVw(top, -5);
              .pxToVw(left, -134);
              .pxToVw(width, 406);
              .pxToVw(height, 406);
            }
            .mobile-box {
              position: absolute;
              .pxToVw(top, 33);
              .pxToVw(left, 34);
              .pxToVw(width, 333);
              .pxToVw(height, 717);
              .mobile-img {
                width: 100%;
              }
            }
          }
        }
        .panel-pc {
          position: absolute;
          left: 0;
          .pxToVw(top, -68);
          .pxToVw(width, 1265);
          .pxToVw(height, 767);
          background-image: url(https://mktv-in-cdn.mockuai.com/16257270063608743.png);
          background-size: cover;
          background-repeat: no-repeat;
          &.fade-enter-active,
          &.fade-leave-active {
            transition: all 0.5s ease-in-out;
          }
          &.fade-enter,
          &.fade-leave-to {
            opacity: 0;
            transform: translateX(20%);
          }
          &.fade-enter-to,
          &.fade-leave {
            opacity: 1;
            transform: translateX(0);
          }
          .screen-box {
            box-sizing: border-box;
            position: absolute;
            .pxToVw(top, 67);
            .pxToVw(left, 164);
            .pxToVw(width, 894);
            .pxToVw(height, 560);
            .pxToVw(padding-top, 96);
            .pxToVw(padding-left, 38);
            background-size: cover;
            background-repeat: no-repeat;
            &.bg-logistics {
              background-image: url(https://mktv-in-cdn.mockuai.com/16257276795427226.png);
            }
            &.bg-cloudhouse {
              background-image: url(https://mktv-in-cdn.mockuai.com/16257276795391950.png);
            }
            &.bg-customer {
              background-image: url(https://mktv-in-cdn.mockuai.com/16257276795304599.png);
            }
            .title {
              .pxToVw(line-height, 54);
              .pxToVw(font-size, 36);
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #ffffff;
            }
            .sub-tabs {
              .pxToVw(margin-top, 66);
              .pxToVw(margin-bottom, 31);
              .sub-tab-item {
                .pxToVw(width, 180);
              }
            }
            .more {
              position: absolute;
              .pxToVw(top, 333);
              .pxToVw(left, 363);
            }
          }
        }
      }
    }
  }
  .slide-tabs {
    // box-sizing: border-box;
    position: relative;
    .pxToVw(padding, 2);
    .pxToVw(height, 54);
    background-color: rgba(255, 255, 255, 0.2);
    .pxToVw(border-radius, 31);
    .active-bar {
      position: absolute;
      .pxToVw(height, 54);
      .pxToVw(border-radius, 31);
      background: linear-gradient(90deg, #ff6600 0%, #fc4440 100%);
      transition: left 0.5s;
    }
    .tabs-box {
      position: absolute;
      display: flex;
      align-items: center;
      // background-color: rgba(255, 255, 255, .2);
      .pxToVw(border-radius, 31);
      .tab-item {
        .pxToVw(line-height, 54);
        text-align: center;
        .pxToVw(border-radius, 31);
        .pxToVw(font-size, 24);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.8);
        cursor: pointer;
        &.active {
          color: #fff;
        }
      }
    }
  }
  .core-section {
    box-sizing: border-box;
    .pxToVw(height, 1231);
    .pxToVw(padding-top, 109);
    background-color: #fff;
    background-image: url(https://mktv-in-cdn.mockuai.com/16256498222339346.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 716 * @unitVw 700 * @unitVw;
    > .title {
      .pxToVw(margin-bottom, 8);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .desc {
      .pxToVw(margin-bottom, 104);
      text-align: center;
      .pxToVw(line-height, 25);
      .pxToVw(font-size, 18);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
    }
    .name {
      display: flex;
      align-items: center;
      justify-content: center;
      .pxToVw(margin-bottom, 130);
      .icon {
        .pxToVw(width, 58);
        .pxToVw(height, 58);
        .pxToVw(margin-right, 10);
      }
      .text {
        .pxToVw(font-size, 36);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
    .content-box {
      position: relative;
      box-sizing: border-box;
      .pxToVw(width, 1440);
      .pxToVw(height, 600);
      .pxToVw(padding-left, 180);
      margin: 0 auto;
      background-color: #001554;
      background-image: url(https://mktv-in-cdn.mockuai.com/16256518357993907.png);
      background-size: cover;
      background-repeat: no-repeat;
      .pxToVw(border-radius, 16);
      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .pxToVw(width, 600);
        height: 100%;
        .title {
          .pxToVw(margin-bottom, 23);
          .pxToVw(line-height, 54);
          .pxToVw(font-size, 32);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
        .slide-tabs {
          .pxToVw(width, 540);
          .pxToVw(margin-bottom, 31);
          .active-bar {
            .pxToVw(width, 180);
          }
          .tab-item {
            .pxToVw(width, 180);
          }
        }
        .points {
          .point-item {
            display: flex;
            align-items: center;
            line-height: 2;
            .icon {
              .pxToVw(width, 20);
              .pxToVw(height, 15);
              .pxToVw(margin-right, 10);
            }
            .text {
              .pxToVw(font-size, 18);
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #d6d6d6;
            }
          }
        }
      }
      .mobile-wrapper {
        position: absolute;
        .pxToVw(right, 117);
        .pxToVw(top, -119);
        .pxToVw(width, 444);
        .pxToVw(height, 834);
        background-image: url(https://mktv-in-cdn.mockuai.com/16256517491543627.png);
        background-size: cover;
        background-repeat: no-repeat;
        .decorate-img {
          position: absolute;
          z-index: 20;
          .pxToVw(top, -5);
          .pxToVw(left, -134);
          .pxToVw(width, 406);
          .pxToVw(height, 406);
        }
        .mobile-box {
          position: absolute;
          .pxToVw(top, 33);
          .pxToVw(left, 34);
          .pxToVw(width, 333);
          .pxToVw(height, 717);
          .mobile-img {
            width: 100%;
          }
        }
      }
    }
  }
  .tools-section {
    box-sizing: border-box;
    .pxToVw(height, 973);
    .pxToVw(padding-top, 12);
    background-color: #fff;
    .name {
      display: flex;
      align-items: center;
      justify-content: center;
      .pxToVw(margin-bottom, 54);
      .icon {
        .pxToVw(width, 58);
        .pxToVw(height, 58);
        .pxToVw(margin-right, 10);
      }
      .text {
        .pxToVw(font-size, 36);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
    .content-box {
      position: relative;
      box-sizing: border-box;
      .pxToVw(height, 691);
      .pxToVw(padding-top, 208);
      background-color: #001554;
      background-image: url(https://mktv-in-cdn.mockuai.com/16256517660396134.png);
      background-size: cover;
      background-repeat: no-repeat;
      .info {
        display: flex;
        flex-direction: column;
        .pxToVw(width, 700);
        height: 100%;
        .pxToVw(margin-left, 960);
        .title {
          .pxToVw(margin-bottom, 23);
          .pxToVw(line-height, 54);
          .pxToVw(font-size, 32);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
        .slide-tabs {
          .pxToVw(width, 600);
          .pxToVw(margin-bottom, 31);
          .active-bar {
            .pxToVw(width, 200);
          }
          .tab-item {
            .pxToVw(width, 200);
          }
        }
        .points {
          .point-item {
            display: flex;
            align-items: center;
            line-height: 2;
            .icon {
              .pxToVw(width, 20);
              .pxToVw(height, 15);
              .pxToVw(margin-right, 10);
            }
            .text {
              .pxToVw(font-size, 18);
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #d6d6d6;
            }
          }
        }
        .more {
          display: block;
          text-decoration: none;
          .pxToVw(margin-top, 30);
          .pxToVw(width, 220);
          .pxToVw(height, 60);
          .pxToVw(line-height, 60);
          text-align: center;
          background-color: #fff;
          .pxToVw(border-radius, 30);
          .pxToVw(font-size, 24);
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ff6600;
          &:hover {
            font-weight: 600;
          }
        }
      }
      .mobile-wrapper {
        position: absolute;
        .pxToVw(left, 399);
        .pxToVw(top, 42);
        .pxToVw(width, 444);
        .pxToVw(height, 834);
        background-image: url(https://mktv-in-cdn.mockuai.com/16256517491543627.png);
        background-size: cover;
        background-repeat: no-repeat;
        .decorate-img {
          position: absolute;
          z-index: 20;
          .pxToVw(top, -5);
          .pxToVw(left, -134);
          .pxToVw(width, 406);
          .pxToVw(height, 406);
        }
        .mobile-box {
          position: absolute;
          .pxToVw(top, 33);
          .pxToVw(left, 34);
          .pxToVw(width, 333);
          .pxToVw(height, 717);
          .mobile-img {
            width: 100%;
          }
        }
      }
    }
  }
  .aftersale-section {
    box-sizing: border-box;
    .pxToVw(height, 932);
    .name {
      display: flex;
      align-items: center;
      justify-content: center;
      .pxToVw(margin-bottom, 73);
      .icon {
        .pxToVw(width, 58);
        .pxToVw(height, 58);
        .pxToVw(margin-right, 10);
      }
      .text {
        .pxToVw(font-size, 36);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
    .content-box {
      box-sizing: border-box;
      position: relative;
      .pxToVw(width, 1679);
      .pxToVw(height, 560);
      .pxToVw(padding-top, 143);
      background-color: #001554;
      background-image: url(https://mktv-in-cdn.mockuai.com/16256517491307362.png);
      background-size: cover;
      background-repeat: no-repeat;
      .pxToVw(border-top-right-radius, 16);
      .pxToVw(border-bottom-right-radius, 16);
      .info {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        .pxToVw(width, 550);
        height: 100%;
        .pxToVw(margin-left, 240);
        .title {
          .pxToVw(margin-bottom, 23);
          .pxToVw(line-height, 54);
          .pxToVw(font-size, 32);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
        .slide-tabs {
          .pxToVw(width, 540);
          .pxToVw(margin-bottom, 31);
          .active-bar {
            .pxToVw(width, 180);
          }
          .tab-item {
            .pxToVw(width, 180);
          }
        }
        .points {
          .point-item {
            display: flex;
            align-items: center;
            line-height: 2;
            .icon {
              .pxToVw(width, 20);
              .pxToVw(height, 15);
              .pxToVw(margin-right, 10);
            }
            .text {
              .pxToVw(font-size, 18);
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #d6d6d6;
            }
          }
        }
        .more {
          display: block;
          text-decoration: none;
          .pxToVw(margin-top, 30);
          .pxToVw(width, 220);
          .pxToVw(height, 60);
          .pxToVw(line-height, 60);
          text-align: center;
          background-color: #fff;
          .pxToVw(border-radius, 30);
          .pxToVw(font-size, 24);
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ff6600;
          &:hover {
            font-weight: 600;
          }
        }
      }
      .computer-wrapper {
        position: absolute;
        .pxToVw(top, 82);
        .pxToVw(right, -90);
        background-image: url(https://mktv-in-cdn.mockuai.com/16275527519182551.png);
        background-size: cover;
        background-repeat: no-repeat;
        .pxToVw(width, 986);
        .pxToVw(height, 603);
        .computer-box {
          position: absolute;
          .pxToVw(left, 128);
          .pxToVw(top, 56);
          .pxToVw(width, 689);
          .pxToVw(height, 425);
          .computer-img {
            width: 100%;
          }
        }
      }
    }
  }
  .empowerment-wrapper {
    box-sizing: border-box;
    .pxToVw(height, 968);
    .pxToVw(padding-top, 112);
    .pxToVw(padding-bottom, 120);
    background: url(https://mktv-in-cdn.mockuai.com/16257307699141603.png)
        bottom right / 547 * @unitVw 491 * @unitVw no-repeat,
      linear-gradient(133deg, #00092a 0%, #001554 100%);
    .content-box {
      position: relative;
      margin: 0 240 * @unitVw;
      &::before,
      &::after {
        content: '';
        position: absolute;
        .pxToVw(width, 34);
        .pxToVw(height, 36);
        background-size: cover;
        background-repeat: no-repeat;
      }
      &::before {
        content: '';
        left: 0;
        .pxToVw(top, -44);
        background-image: url(https://mktv-in-cdn.mockuai.com/16257305441386723.png);
      }
      &::after {
        content: '';
        right: 0;
        .pxToVw(top, 88);
        background-image: url(https://mktv-in-cdn.mockuai.com/16275694200594491.png);
      }
      .top {
        display: flex;
        align-items: center;
        .pxToVw(margin-bottom, 100);
        .title {
          // .pxToVw(width, 480);
          flex: 1;
          // .pxToVw(margin-right, 138);
          line-height: 1.4;
          .pxToVw(font-size, 40);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
        .desc {
          // flex: 1;
          .pxToVw(width, 820);
          .pxToVw(line-height, 40);
          .pxToVw(font-size, 24);
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: rgba(255, 255, 255, 0.8);
        }
      }
      .slides-wrapper {
        display: flex;
        align-items: center;
        .slide-item {
          transition: all 0.35s;
          position: relative;
          .pxToVw(width, 200);
          .pxToVw(height, 520);
          background-color: #001554;
          .pxToVw(border-radius, 16);
          .pxToVw(margin-right, 20);
          background-size: cover;
          background-repeat: no-repeat;
          &:last-child {
            margin-right: 0;
          }
          &.active {
            .pxToVw(width, 780);
            .info {
              .pxToVw(left, 40);
              .pxToVw(bottom, 34);
            }
          }
          &.bg-prod {
            background-image: url(https://mktv-in-cdn.mockuai.com/16257351311558554.png);
            &.active {
              background-image: url(https://mktv-in-cdn.mockuai.com/16257351311593435.png);
            }
          }
          &.bg-brand {
            background-image: url(https://mktv-in-cdn.mockuai.com/16257351311494748.png);
            &.active {
              background-image: url(https://mktv-in-cdn.mockuai.com/16257351311529186.png);
            }
          }
          &.bg-live {
            background-image: url(https://mktv-in-cdn.mockuai.com/16257351311674011.png);
            &.active {
              background-image: url(https://mktv-in-cdn.mockuai.com/16257351311712795.png);
            }
          }
          &.bg-operation {
            background-image: url(https://mktv-in-cdn.mockuai.com/16257351311638685.png);
            &.active {
              background-image: url(https://mktv-in-cdn.mockuai.com/16257351311395288.png);
            }
          }
          .mask {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(
              180deg,
              rgba(0, 47, 141, 0) 0%,
              #001554 100%
            );
            opacity: 0.6;
          }
          .info {
            transition: all 0.35s;
            position: absolute;
            .pxToVw(height, 65);
            .pxToVw(bottom, 34);
            .pxToVw(left, 52);
            .title {
              .pxToVw(font-size, 24);
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #fff;
            }
            .points {
              display: flex;
              align-items: center;
              .pxToVw(height, 30);
              .pxToVw(margin-top, 8);
              &.fade-enter-active,
              .fade-leave-active {
                transition: opacity 1s;
              }
              &.fade-enter,
              .fade-leave-to {
                opacity: 0;
              }
              .point-item {
                .pxToVw(margin-right, 40);
                .icon {
                  .pxToVw(width, 20);
                  .pxToVw(height, 15);
                  .pxToVw(margin-right, 10);
                }
                .text {
                  .pxToVw(line-height, 30);
                  .pxToVw(font-size, 18);
                  font-family: PingFangSC-Light, PingFang SC;
                  font-weight: 300;
                  color: rgba(255, 255, 255, 0.8);
                }
              }
            }
          }
        }
      }
    }
  }
  .brands-wrapper {
    box-sizing: border-box;
    .pxToVw(height, 850);
    .pxToVw(padding-top, 89);
    .pxToVw(padding-bottom, 100);
    background-color: #f8f8f8;
    .title {
      .pxToVw(margin-bottom, 91);
      text-align: center;
      .pxToVw(line-height, 50);
      .pxToVw(font-size, 36);
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .logo-box {
      position: relative;
      .pxToVw(width, 1440);
      .pxToVw(height, 360);
      overflow: hidden;
      margin: 0 auto;
      .brand-img {
        width: 100%;
        display: block;
      }
      .box-container {
        animation: rolling 100s linear infinite;
        position: absolute;
        left: 0;
        display: flex;
        align-items: center;
        .pxToVw(width, 1440);
        .pxToVw(height, 360);
      }
      .box-1 {
        .pxToVw(margin-right, 60);
      }
      .box-1,
      .box-2 {
        .pxToVw(width, 2940);
        .pxToVw(height, 360);
        img {
          height: 100%;
        }
      }
    }
    .btn {
      margin: 100 * @unitVw auto 0;
      .pxToVw(width, 240);
      .pxToVw(height, 60);
      .pxToVw(line-height, 60);
      text-align: center;
      background-color: #ff6600;
      .pxToVw(border-radius, 30);
      .pxToVw(font-size, 24);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
      &:hover {
        font-weight: 600;
        box-shadow: 0px 7px 21px 0px rgba(255, 102, 0, 0.4);
      }
    }
  }
}
</style>
