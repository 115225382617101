<template>
  <div class="store-container">
    <shrinkable-header ref="header" class="store-header">
      <p-header :fixed="false" type="flow"></p-header>
      <sub-header title="商家">
        <template v-slot:title>
          <div class="box">
            <img src="https://mktv-in-cdn.mockuai.com/16276674660155723.png" class="icon" />
            <span class="text">商家合作</span>
          </div>
        </template>
        <ul class="nav">
          <li class="nav-item" data-menuanchor="solution" @click="changeMoveMode">
            <a href="#solution">商家解决方案</a>
          </li>
          <li class="nav-item" data-menuanchor="standard" @click="changeMoveMode">
            <a href="#standard">合作标准</a>
          </li>
          <li class="nav-item" data-menuanchor="guide" @click="changeMoveMode">
            <a href="#guide">入驻指南</a>
          </li>
          <li class="nav-item">
            <a href="/source-factory">货源工厂</a>
          </li>
        </ul>
      </sub-header>
    </shrinkable-header>
    <tools-bar ref="toolsBar" @backtop="onBacktop"></tools-bar>
    <div id="store-fullpage">
      <div ref="firstSection" class="section fp-auto-height-responsive">
        <solution-section></solution-section>
      </div>
      <div class="section fp-auto-height-responsive">
        <standard-section></standard-section>
      </div>
      <div class="section fp-auto-height-responsive">
        <guide-section></guide-section>
      </div>
    </div>
  </div>
</template>

<script>
import { decode } from 'js-base64'
import Header from '@/layout/Header.vue'
import SubHeader from '@/components/SubHeader.vue'
import ShrinkableHeader from '@/components/ShrinkableHeader.vue'
import SolutionSection from '@/components/store/SolutionSection.vue'
import StandardSection from '@/components/store/StandardSection.vue'
import GuideSection from '@/components/store/GuideSection.vue'
import ToolsBar from '@/components/ToolsBar.vue'

let isFirstSection = false
let anchorMove = false

export default {
  head: {
    title: '商家合作-商家品牌合作-直播带货品牌-商家直播合作-魔筷科技',
    meta: [
      {
        name: 'Keywords',
        content: '商家带货合作,商家合作电话,品牌直播带货,直播带货服务,品牌孵化运营'
      },
      {
        name: 'Description',
        content: '【魔筷科技-直播电商服务机构】商家怎么找直播机构合作？魔筷拥有专业的品牌运营和孵化团队,为商家输出公司成熟的直播电商运营经验,帮助商家成长,共同打造爆款产品。'
      }
    ]
  },
  components: {
    'p-header': Header,
    SubHeader,
    ShrinkableHeader,
    SolutionSection,
    StandardSection,
    GuideSection,
    ToolsBar
  },
  mounted() {
    this.initFullpage()
    this.$refs.toolsBar.show()
  },
  destroyed() {
    window.fullpage_api.destroy()
  },
  methods: {
    initFullpage() {
      require('fullpage.js/vendors/scrolloverflow.min.js')
      const Fullpage = require("fullpage.js");
      const vm = this
      new Fullpage('#store-fullpage', {
        licenseKey: decode(window.__INITIAL_STATE__.fullpageKey),
        anchors: ['solution', 'standard', 'guide'],
        menu: '.sub-header .nav',
        verticalCentered: false,
        scrollOverflow: true,
        fixedElements: '.store-header, .video-modal',
        afterLoad(origin, dest) {
          isFirstSection = dest.isFirst
          let iscroll = document.querySelector('.fp-section.active .fp-scrollable') && document.querySelector('.fp-section.active .fp-scrollable').fp_iscrollInstance
          if (anchorMove && iscroll) {
            iscroll.scrollTo(0, 0, 600)
          }
          
          if(dest.isFirst) {
            vm.$refs.header.changeStyle('top', 0)
          } else {
            vm.$refs.header.changeStyle('top', '-90px')
          }
        },
        afterRender() {
          window.addEventListener('wheel', () => {
            anchorMove = false
            if (isFirstSection) {
              let ele = vm.$refs.firstSection.querySelector('.fp-scrollable .fp-scroller')
              const transformStr = window.getComputedStyle(ele).getPropertyValue('transform')
              const y = Math.abs(transformStr.split(',')[transformStr.split(',').length - 1].split(')')[0])
              if (y <= 90) {
                vm.$refs.header.changeStyle('top', `-${y}px`)
                vm.$refs.firstSection.style.paddingTop = `${150 - y}px`
              } else {
                vm.$refs.header.changeStyle('top', '-90px')
                vm.$refs.firstSection.style.paddingTop = '60px'
              }
              window.fullpage_api.reBuild() 
            }
          })
        }
      })
    },
    onBacktop() {
      window.fullpage_api.moveTo(1)
      let iscroll = document.querySelector('.fp-section.active .fp-scrollable') && document.querySelector('.fp-section.active .fp-scrollable').fp_iscrollInstance
      if (iscroll) {
        iscroll.scrollTo(0, 0, 600)
      }
    },
    changeMoveMode() {
      anchorMove = true
    }
  }
}
</script>

<style lang="less" scoped>
  .box {
    display: flex;
    align-items: center;
    .icon {
      width: 28px;
      height: 28px;
      margin-right: 9px;
    }
    .text {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FF6600;
    }
  }
  .store-container {
    .section {
      transition: all .5s;
      &:nth-child(1) {
        padding-top: 150px;
      }
      &:nth-child(2) {
        padding-top: 60px;
      }
      &:nth-child(3) {
        padding-top: 60px;
      }
    }
  }
</style>
