<template>
  <div class="sub-header" :style="style">
    <div class="sub-header-container">
      <!-- <div 
        v-if="title" 
        class="title"
        :style="{ color: titleColor }"
      >
        {{title}}
      </div> -->
      <div class="title-box" v-if="!title_box_show">
        <slot name="title"></slot>
      </div>

      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    titleColor: {
      type: String,
      default: '#ff6600',
    },
    title_box_show: Boolean,
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      return this.fixed
        ? { position: 'fixed', width: '100%', zIndex: '100' }
        : {}
    },
  },
}
</script>

<style lang="less" scoped>
.sub-header {
  box-sizing: border-box;
  height: 60px;
  background-color: #fff;

  // padding-left: 240px;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.07);
  border-top: 1px solid #ddd;
  .sub-header-container {
    display: flex;
    align-items: center;
    height: 60px;
    margin: 0 30px;

    .title {
      margin-right: 130px;
      line-height: 1.375;
      color: #ff6600;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      white-space: pre;
    }
    .title-box {
      margin-right: 68px;
    }
    /deep/ .nav {
      display: flex;
      align-items: center;
      .nav-item {
        margin-right: 60px;

        &.active {
          a {
            position: relative;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            &::after {
              content: '';
              position: absolute;
              bottom: -7px;
              left: 50%;
              transform: translateX(-50%);
              width: 20px;
              height: 2px;
              background-color: #ff6600;
            }
          }
        }
        a {
          text-decoration: none;
          line-height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          white-space: pre;
          &:hover {
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
          }
        }
      }
    }
  }
}
@media screen and(min-width: 1920px) {
  .sub-header {
    .sub-header-container {
      width: 1440px;
      margin: 0 auto;
    }
  }
}
@media screen and (min-width: 1640px) and (max-width: 1920px) {
  .sub-header {
    .sub-header-container {
      margin-left: 240px;
    }
  }
}
@media screen and(max-width: 1640px) {
  .sub-header {
    .sub-header-container {
      margin-left: 30px;
    }
  }
}
</style>
