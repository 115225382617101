<template>
  <div v-show="visible" class="consult-phone">
    <div class="title">咨询企业微信</div>
    <img src="https://mk-crm-cdn.mockuai.com/home/1723083857135420364.jpg" alt="" class="qrcode">
    <div class="desc">添加企业微信，了解更多</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    close() {
      this.visible = false
    }
  }
}
</script>


<style lang="less" scoped>
  .consult-phone {
    box-sizing: border-box;
    position: fixed;
    z-index: 1500;
    top: 56%;
    right: 112px;
    transform: translate(0, -50%);
    width: 240px;
    height: 320px;
    padding: 20px 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    .title {
      margin-bottom: 8px;
      line-height: 25px;
      color: #333;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .phone {
      margin-bottom: 14px;
      line-height: 46px;
      color: #333;
      font-size: 40px;
      font-family: DINCond-Medium, DINCond;
      font-weight: 500;
    }
    .qrcode {
      width: 200px;
      height: 200px;
      margin-bottom: 18px;
      object-fit: cover;
    }
    .desc {
      color: #333;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
  }
</style>
