<template>
  <div
    v-if="visible"
    class="shrinkable-header"
    :class="headerClass"
    :style="headerStyle"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    headerClass: String,
  },
  data() {
    return {
      visible: false,
      usedUrls: ['Anchor', 'Store', 'SourceFactory', 'Scrm', 'knowledge-base'],
      headerStyle: {},
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal) {
        console.log("route changed", newVal);
        if (this.usedUrls.includes(newVal.name)) {
          this.visible = true;
        } else {
          this.visible = false;
        }
      },
    },
  },
  methods: {
    changeStyle(p, v) {
      this.$set(this.headerStyle, p, v);
    },
  },
};
</script>

<style lang="less" scoped>
.shrinkable-header {
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.35s;
  // overflow: hidden;
  z-index: 100;
}
</style>
